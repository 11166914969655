import axios from "axios";
import Airtable from "airtable";
import Component from "../classes/Component";
import { mapEach } from "../utils/dom";
import { preloadImage } from "../utils/image";
import Modal from "./Modal";
import AutoBind from "../utils/bind";

export default class Spotify extends Component {
  constructor() {
    super({
      element: ".c-home",
      elements: {
        title: "[data-title]",
        artist: "[data-artist]",
        coverImage: "[data-cover]",
        video: "[data-video]",
        videoElement: "[data-video] video",
        indicator: "[data-indicator]",
        link: "[data-link]",
        main: "[data-main]",
        button: "[data-button]",
        buttonText: "[data-button-text]",
        progressBar: "[data-progress-bar]",
        preloaderButton: "[data-preloader-button]",
        preloader: "[data-preloader]",
      },
    });

    AutoBind(this);

    Airtable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: process.env.AIRTABLE_API_KEY,
    });

    this.base = Airtable.base(process.env.AIRTABLE_BASE_ID);

    this.oldLink = "";

    this.audio = new Audio();
    this.audio.volume = 0;
    this.elements.videoElement.volume = 0.1;
    this.volume = 0;

    this.elements.video.classList.add("hidden");
    this.elements.videoElement.muted = true;

    this.modal = new Modal();

    this.initPreloader();
  }

  async getNewToken() {
    // application/x-www-form-urlencoded parameters
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", process.env.SPOTIFY_REFRESH_TOKEN);

    const encodedSecret = Buffer.from(
      process.env.SPOTIFY_CLIENT_ID + ":" + process.env.SPOTIFY_CLIENT_SECRET
    ).toString("base64");

    const headers = {
      Authorization: `Basic ${encodedSecret}`,
    };

    // fetch token with POST request
    const res = await axios.post(
      "https://accounts.spotify.com/api/token",
      params,
      { headers }
    );

    return res;
  }

  async updateToken() {
    // get new token from Spotify

    const res = await this.getNewToken();
    const resData = res.data;

    // Spotify tokens expire after 1 hour. We convert the expiry time to milliseconds and take 300000ms off to account for any latency.
    const created = Date.now();
    const token = {
      token: resData.access_token,
      expiry: (resData.expires_in - 300) * 1000,
      created,
    };

    // update global token variable
    this.token = token;

    // update Airtable
    await this.base("token").update([
      {
        id: "reclbFlyBFqatRm9L",
        fields: {
          ...token,
        },
      },
    ]);
  }

  async init() {
    try {
      const tokenValid = (token = {}) => {
        const now = Date.now();
        const expiry = Number(token.created) + Number(token.expiry);

        return now < expiry;
      };

      // get token data from Airtable
      const res = await this.base("token").select().firstPage();
      this.token = res[0].fields;

      // check if token exists and if it hasn't expired, if it has, then get a new one
      if (this.token && !tokenValid(this.token)) {
        await this.updateToken();
      }

      // if token doesn't exist, get a new one. This happens when there's no initial data in airtable
      if (!this.token) {
        await this.updateToken();
      }

      const playing = await this.getNowPlaying();
      // this function updates the DOM with the now playing data
      this.setNowPlaying(playing);
    } catch (error) {
      if (error.response) {
        if (error.response.data.error.message === "The access token expired") {
          // if Spotify is being weird and the token has expired before it is actually suppsed to, get a new one.
          await this.updateToken();

          const playing = await this.getNowPlaying();
          this.setNowPlaying(playing);
        }
      }
    }
  }

  async getNowPlaying() {
    let songName = "";
    let isPlaying = false;
    let artistName = "";
    let url = "";
    let coverImageUrl = "";
    let previewUrl = "";

    const headers = {
      Authorization: `Bearer ${this.token.token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // fetch currently playing data.

    const res = await axios.get(
      "https://api.spotify.com/v1/me/player/currently-playing",
      { headers }
    );

    // if there is no data, then the user is not playing anything, so we fetch the last played song instead.
    if (
      res.data.is_playing === false ||
      res.data.currently_playing_type !== "track"
    ) {
      const res = await axios.get(
        "https://api.spotify.com/v1/me/player/recently-played?limit=1",
        { headers }
      );

      const playHistory = res.data.items;
      const recentTrack = playHistory[0].track;

      songName = recentTrack.name;
      isPlaying = false;
      artistName = recentTrack.artists[0].name;
      url = recentTrack.external_urls.spotify;
      coverImageUrl = recentTrack.album.images[0].url;
      previewUrl = recentTrack.preview_url;
    } else {
      const track = res.data.item;

      songName = track.name;
      isPlaying = res.data.is_playing;
      artistName = track.artists[0].name;
      url = track.external_urls.spotify;
      coverImageUrl = track.album.images[0].url;
      previewUrl = track.preview_url;
    }

    return {
      artistName,
      isPlaying,
      songName,
      url,
      coverImageUrl,
      previewUrl,
    };
  }

  async updateDomElements(playing) {
    this.modal.closeModal();

    const { artistName, songName, url, coverImageUrl, isPlaying, previewUrl } =
      playing;
    const {
      title,
      artist,
      indicator,
      coverImage,
      video,
      videoElement,
      link,
      main,
    } = this.elements;

    title.innerHTML = songName;
    link.href = url;
    artist.innerHTML = artistName;

    await preloadImage(coverImageUrl);

    setTimeout(() => {
      this.updateButtonText("preview");
      video.classList.add("hidden");
      videoElement.muted = true;
      main.classList.add("enter");

      if (isPlaying) {
        indicator.classList.add("live");
      } else {
        indicator.classList.remove("live");
      }

      this.audio.src = previewUrl;
    }, 500);

    mapEach(coverImage, (image) => {
      image.innerHTML = `<img src="${coverImageUrl}" alt="${songName} by ${artistName}" />`;
    });

    this.updateMetaTags(playing);
  }

  // update DOM with now playing data
  async setNowPlaying(playing) {
    this.updateDomElements(playing);

    const { main } = this.elements;

    main.classList.add("active");

    this.oldLink = playing.url;

    setInterval(() => {
      this.fetchNewSong();
    }, 3000);
  }

  async setNextPlaying(playing) {
    if (playing.url !== this.oldLink) {
      const { videoElement, video, main } = this.elements;

      this.resetAudio();

      videoElement.currentTime = 0.0;
      videoElement.muted = false;
      video.classList.remove("hidden");
      main.classList.remove("enter");
      this.updateDomElements(playing);
    }

    this.oldLink = playing.url;
  }

  async fetchNewSong() {
    try {
      const playing = await this.getNowPlaying();
      this.setNextPlaying(playing);
    } catch (error) {
      if (error.response) {
        if (error.response.data.error.message === "The access token expired") {
          // if Spotify is being weird and the token has expired before it is actually suppsed to, get a new one.
          await this.updateToken();
          const playing = await this.getNowPlaying();
          this.setNextPlaying(playing);
        }
      }
    }
  }

  updateAudioSource(src) {
    this.audio.src = src;
  }

  addAudioEventListeners() {
    const audio = this.audio;
    const { progressBar } = this.elements;

    audio.addEventListener("timeupdate", () => {
      const progress = audio.currentTime / audio.duration;

      progressBar.style.transform = `translateX(calc(${
        progress * 100
      }vw - 4px)`;

      const interval = 200; // 200ms interval

      if (audio.volume == 0) {
        var intervalID = setInterval(() => {
          if (this.volume < 0.5) {
            this.volume += 0.02;
            // limit to 2 decimal places
            // also converts to string, works ok
            audio.volume = this.volume.toFixed(2);
          } else {
            // Stop the setInterval when 0 is reached
            clearInterval(intervalID);
          }
        }, interval);
      }

      if (Math.round(audio.currentTime) === 28) {
        var intervalID = setInterval(() => {
          if (this.volume > 0) {
            this.volume -= 0.02;
            // limit to 2 decimal places
            // also converts to string, works ok
            audio.volume = Math.max(this.volume.toFixed(2), 0);
          } else {
            // Stop the setInterval when 0 is reached
            clearInterval(intervalID);
          }
        }, interval);
      }
    });

    audio.addEventListener("ended", () => {
      progressBar.classList.remove("active");
      progressBar.style.transform = `translateX(0)`;
      this.updateButtonText("preview");

      setTimeout(() => {
        this.audio.volume = 0;
        this.volume = 0;
      }, 100);
    });

    audio.addEventListener("error", () => {
      progressBar.classList.remove("active");
    });

    audio.addEventListener("play", () => {
      progressBar.classList.add("active");
      this.updateButtonText("pause");
    });

    audio.addEventListener("pause", () => {
      this.updateButtonText("play");
    });
  }

  addEventListeners() {
    const { button } = this.elements;

    button.addEventListener("click", () => {
      if (this.audio.paused) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    });
  }

  updateButtonText(text) {
    mapEach(this.elements.buttonText, (el) => {
      el.innerHTML = text;
    });
  }

  resetAudio() {
    const { progressBar } = this.elements;

    this.audio.pause();
    this.audio = null;
    this.audio = new Audio();
    this.addAudioEventListeners();
    this.audio.volume = 0;
    this.volume = 0;

    progressBar.classList.remove("active");

    setTimeout(() => {
      progressBar.style.transform = `translateX(0px)`;
    }, 100);
  }

  updateMetaTags(playing) {
    const { songName, artistName, isPlaying } = playing;

    if (isPlaying) {
      document.title = `Seyi is listening to ${songName} by ${artistName}`;
    } else {
      document.title = `Seyi last listened to ${songName} by ${artistName}`;
    }
  }

  async onPreloaded() {
    this.init();

    const { preloader, videoElement, video } = this.elements;

    video.classList.remove("hidden");
    videoElement.muted = false;
    videoElement.currentTime = 0.0;
    videoElement.autoPlay = true;
    videoElement.play();

    preloader.style.display = "none";

    // once token has been fetched/updated, get now playing data

    this.addAudioEventListeners();
    this.addEventListeners();
  }

  initPreloader() {
    const { preloaderButton } = this.elements;

    preloaderButton.addEventListener("click", () => {
      this.onPreloaded();
    });
  }
}
