const map = (array, iteratee) => {
  let index = -1;
  const length = array == null ? 0 : array.length;
  const result = new Array(length);

  while (++index < length) {
    result[index] = iteratee(array[index], index, array);
  }
  return result;
};

export const mapElements = (element, object) => {
  if (element instanceof HTMLElement) {
    element = element;
  } else {
    element = document.querySelector(element);
  }

  const elements = {};

  Object.keys(object).forEach((key) => {
    const entry = object[key];

    if (
      entry instanceof HTMLElement ||
      entry instanceof NodeList ||
      Array.isArray(entry)
    ) {
      elements[key] = entry;
    } else {
      elements[key] = element.querySelectorAll(entry);

      if (elements[key].length === 0) {
        elements[key] = null;
      } else if (elements[key].length === 1) {
        elements[key] = element.querySelector(entry);
      }
    }
  });

  return elements;
};

export const mapEach = (element, callback) => {
  if (element instanceof window.HTMLElement) {
    return [callback(element)];
  }

  return map(element, callback);
};
