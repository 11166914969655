import Component from "../classes/Component";

export default class Modal extends Component {
  constructor() {
    super({
      element: ".c-home",
      elements: {
        modal: "[data-modal]",
        modalBackdrop: "[data-modal-backdrop]",
        modalOpen: "[data-modal-open]",
        modalClose: "[data-modal-close]",
        modalContent: "[data-modal-content]",
      },
    });

    this.addEventListeners();
  }

  openModal() {
    const { modal } = this.elements;
    modal.classList.add("active");

    setTimeout(() => {
      modal.focus();
    }, 100);
  }

  closeModal() {
    const { modal } = this.elements;
    modal.classList.remove("active");
    modal.blur();
  }

  addEventListeners() {
    const { modal, modalOpen, modalClose, modalBackdrop, modalContent } =
      this.elements;

    modalOpen.addEventListener("click", this.openModal);
    modalClose.addEventListener("click", this.closeModal);
    modalBackdrop.addEventListener("click", this.closeModal);

    // close on escape key press

    modal.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        this.closeModal();
      }
    });

    setTimeout(() => {
      modalContent.style.transition = "transform 350ms ease-in-out";
    }, 300);
  }
}
